import React from "react";
import { Link } from "gatsby";



import Headline from "../../components/headline";

import Head from "../../components/head";
import HeroHeader from "../../components/HeroHeader";
import TextWrapAroundImg from "../../components/textWrapAroundImg";
import tADKStyles from "./tss-adirondack.module.css";

import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulTssAdkPage {
      edges {
        node {
          headerImage {
            file {
              url
            }
          }
          headerHeadline {
            headerHeadline
          }
          headerSubheadline {
            headerSubheadline
          }
          section1Image {
            file {
              url
            }
          }
          section1Headline {
            section1Headline
          }
          section1Paragraph {
            section1Paragraph
          }
          section2Image {
            file {
              url
            }
          }
          section2ImageCaption
        }
      }
    }
  }
`;
const TSSADK = props => {
  return (
    <>
      <Head title="TSS training | Adirondack"></Head>
      <HeroHeader
        location={props.location}
        BGImg={
          props.data.allContentfulTssAdkPage.edges[0].node.headerImage.file.url
        }
        heading={
          props.data.allContentfulTssAdkPage.edges[0].node.headerHeadline
            .headerHeadline
        }
        subHeading={
          props.data.allContentfulTssAdkPage.edges[0].node.headerSubheadline
            .headerSubheadline
        }
        buttonText="Help Us"
      ></HeroHeader>

      <Headline
        title={
          props.data.allContentfulTssAdkPage.edges[0].node.section1Headline
            .section1Headline
        }
        lightBackground={true}
      ></Headline>
      <TextWrapAroundImg
        imgRight={
          props.data.allContentfulTssAdkPage.edges[0].node.section1Image.file
            .url
        }
        paragraphHeader={false}
        lightBackground={true}
      >
        {" "}
        <p style={{ whiteSpace: "pre-wrap" }}>
          {
            props.data.allContentfulTssAdkPage.edges[0].node.section1Paragraph
              .section1Paragraph
          }
        </p>
      </TextWrapAroundImg>
      <section id={tADKStyles.testimonial}>
        <div className={tADKStyles.innerContainer}>
          <div style={{ textAlign: "center" }}>
            <img
              src={
                props.data.allContentfulTssAdkPage.edges[0].node.section2Image
                  .file.url
              }
            ></img>
            <p style={{ margin: "0", fontSize: "14px" }}>
              (
              {
                props.data.allContentfulTssAdkPage.edges[0].node
                  .section2ImageCaption
              }
              )
            </p>
          </div>
          <p>
            On September 24, a day after the last Sunday service and its
            featured student soloist, Rev. Weitz emailed Marie's husband, our
            CEO:
          </p>
          <p className={tADKStyles.inner}>
            What happened this past summer has given purpose and hope to the
            congregation. Music has lifted them to new heights that they never
            thought possible.
          </p>

          <p>Her husband's response to "Friends of TSS" was as follows:</p>
          <p className={tADKStyles.inner}>
            What I observed this summer is what Marie always said leaving
            performances in the Franklin College Chamber Music Series at UGA.
            Some of you have heard it from me before — the world being a better
            place with that kind of music on the 'street-corners', etc. That’s
            what showed up this summer, an outpouring of acceptance by rural
            folks not often exposed to live refined music.
          </p>
          <p className={tADKStyles.inner}>
            I want to tell you about one of these 'outpourings' — it was after
            the last concert, the all-Mozart program performed by a string trio.
            The violist’s instrument predated (1737) Mozart himself. The second
            half of the program was mature Mozart, K563 (1788), an hour long.
            After the prolonged standing ovation that ended the program, people
            filing out, me in the front row waiting my turn—against this exit
            stream I saw a man making his way toward me. Something about his
            demeanor kept my attention. He was intent, subdued, and serious. He
            approached me, shook my hand in both of his, thanked me, then said
            two things I will never forget. First, "I have never heard anything
            like that in all my life." Then, after a pause, "I think my life has
            been changed." And he turned and left, never seeing my moistening
            eyes or sensing Marie’s hand touch my shoulder.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Link
              to="/donate"
              style={{
                textDecoration: "none",
                color: "#ba0c2f",
                fontWeight: "600",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              Does it get any better?
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};
export default TSSADK;
